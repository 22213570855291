<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <el-tabs v-model="query.addressType" type="card" stretch style="width: 320px;">
          <el-tab-pane label="自定义区域" name="area">
            <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" v-if="query.addressType=='area'" @change="toQuery" />
          </el-tab-pane>
          <el-tab-pane label="标准行政区域" name="standard">
            <province-select v-model="query.treeNodeId" :type.sync="query.StandardAreaGroup" v-if="query.addressType=='standard'" @change="toQuery" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="flex v">
      <el-form class="head-container e" label-position="top">
        <el-form-item label="时间范围" class="filter-item">
          <div class="h c">
            <el-date-picker v-model="query.begDate" type="date" value-format="timestamp" placeholder="选择开始日" :clearable="false" style="width:150px" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="date" value-format="timestamp" placeholder="选择结束日" :clearable="false" style="width:150px" />
          </div>
        </el-form-item>

        <el-form-item label="汇总级别" class="filter-item">
          <el-select v-model="multParams" placeholder style="width: 150px;">
            <el-option v-for="(r, inx) in rankDefines" :key="r.name" :label="r.name" :value="inx" />
          </el-select>
        </el-form-item>

        <el-form-item class="filter-item">
          <el-button type="success" icon="el-icon-search" :loading="loading" @click="toQuery" :disabled="!searchable">统计</el-button>
          <el-button type="success" @click="toDownload" :disabled="!searchable" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
        </el-form-item>
      </el-form>
      <div class="flex scroll-able">
        <template v-if="store && store.length">
          <el-table row-key="id" border :data="data" size="small" height="530">
            <el-table-column prop="rank" label="排名" />
            <el-table-column prop="name" label="地区/经销商" show-overflow-tooltip min-width="100" />
            <el-table-column key="code" prop="code" label="经销商编码" width="150" v-if="analysisType === 1" />
            <el-table-column prop="saleFormCount" label="零售单数量" show-overflow-tooltip min-width="100" />
            <el-table-column prop="purchaseOrderFormCount" label="已采购零售单数量" show-overflow-tooltip min-width="100" />
            <el-table-column prop="purchaseFormCount" label="采购单数量" show-overflow-tooltip min-width="100" />
            <el-table-column prop="unsourcePurchaseFormCount" label="无来源采购单数量" show-overflow-tooltip min-width="100" />
            <el-table-column prop="ratio" label="转换率" min-width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.purchaseRatio">{{ Math.round(scope.row.purchaseRatio * 10000) / 100}}%</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination align="center" :current-page.sync="page" :page-sizes="[5,10,20, 50]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="store.length" />
        </template>
        <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
    </div>
  </div>
</template>

<script>
import deptSelect from "./deptSelect";
import provinceSelect from "./provinceSelect";
import skuSelector from "@/views/assembly/skuSelect";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  name: "trend",
  components: {
    deptSelect,
    skuSelector,
    provinceSelect,
  },
  data() {
    let now = new Date(this.$now.get());
    return {
      loading: false,
      goodsSpuName: null,
      store: [],
      chartTypes: "zzt",
      page: 1,
      size: 10,
      chart: null,
      downloadLoading: false,
      columns: {
        realSumMoney: { label: "销售金额", show: true },
        saleNumber: { label: "销售数量", show: true },
        realSendMoney: { label: "发货金额", show: true },
        sendNumber: { label: "发货数量", show: true },
      },
      rankDefines: [
        {
          name: "区域",
          value: { AreaGroupType: "area", shopAreaGroup: "area" },
        },
        {
          name: "经销商",
          value: { AreaGroupType: "area", shopAreaGroup: "distributor" },
        },
        {
          name: "省",
          value: { AreaGroupType: "standard", cityAreaGroup: "province" },
        },
        {
          name: "市",
          value: { AreaGroupType: "standard", cityAreaGroup: "city" },
        },
      ],
      multParams: 0,
      analysisType: 0,
      query: {
        addressType: "area",
        treeNodeId: null,
        treeNodeType: null,
        StandardAreaGroup: null,
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        purchaseValueGroup: "realSumMoney",
        AreaGroupType: "saleValueGroup",
        goods: null,
        skuId: null,
        brandId: null,
        seriesId: null,
        categoryId: null,
        page: 0,
        size: 10000,
      },
    };
  },

  computed: {
    data() {
      return (this.store || []).slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
    },
    searchable() {
      return (
        this.query.begDate &&
        this.query.endDate &&
        this.query.purchaseValueGroup
      );
    },
  },
  methods: {
    handleGoodsSelect(res) {
      if (res) {
        this.query.goods = res;
      }
    },
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      Object.assign(data, this.rankDefines[this.multParams].value);

      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      if (this.query.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          data.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          data.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          data.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          data.StandardAreaGroup = "district";
        }
        delete data.treeNodeType;
      } else {
        data.treeNodeType = this.query.treeNodeType;
        delete data.StandardAreaGroup;
      }
      delete data.goods;

      return data;
    },
    toQuery() {
      let data = this.makeParams();
      this.analysisType = this.multParams;
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/purchase/sale/ratio",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
        })
        .catch((err) => {
          this.store = [];
        })
        .finally((_) => {
          this.loading = false;
        });
    },

    toDownload() {
      let params = this.makeParams();
      this.downloadLoading = true;
      download("@host:analysis;api/analy/purchase/download/analyRatio", params)
        .then((result) => {
          downloadFile(result, "转换率分析", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>

